.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 2vh auto;
    overflow: auto;
}

/* Основное окно email */
.emailContainer {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    position: relative;
    overflow-y: auto;
    max-height: 70vh;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

/* Получатели */
.recipientsWrapper {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 15px;
}

.recipientsTable {
    width: 100%;
    border-collapse: collapse;
}

.recipientsTable th,
.recipientsTable td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
}

.emailCell {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Статусы */
.success {
    color: green;
    font-weight: bold;
}

.error {
    color: red;
    font-weight: bold;
}

.bannerHeader,
.bannerFooter {
    text-align: center;
    padding: 20px;
}

.content {
    padding: 20px;
    color: #333333;
    line-height: 1.5;
}

.bannerHeader img,
.bannerImage img,
.bannerFooter img {
    width: 100%;
    height: auto;
    display: block;
}

/* CTA-кнопка */
.callToAction {
    text-align: center;
    margin: 20px 0;
}

.callToAction a {
    text-decoration: none;
    padding: 15px 30px;
    background: linear-gradient(90deg, #2ecc71, #27ae60);
    color: #ffffff;
    border-radius: 8px;
    font-weight: bold;
    display: inline-block;
    transition: background 0.3s, transform 0.3s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.callToAction a:hover {
    background: linear-gradient(90deg, #27ae60, #2ecc71);
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Подвал */
.signature {
    font-size: 12px;
    color: #777777;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #ddd;
}

/* Мобильные стили */
@media only screen and (max-width: 600px) {
    .emailContainer {
        padding: 1rem;
        max-width: 100%;
    }

    .recipientsWrapper {
        overflow-x: auto;
    }

    .recipientsTable {
        font-size: 12px;
    }

    .closeButton {
        font-size: 1.2rem;
        top: 5px;
        right: 10px;
    }
}