.ordersContainer {
    padding: 20px;
    background-color: #f5f7fa;
    max-width: 1200px;
    margin: 0 auto;
}

h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
}

.searchInput {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.ordersGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    justify-content: center;
}

.orderCard {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    text-align: left;
    position: relative;
}

.icon {
    margin-right: 8px;
    color: #555;
}

.orderCard:hover {
    transform: translateY(-3px);
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.15);
}

.orderId {
    font-weight: bold;
    color: var(--botique-blue);
}

.status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background: #ff9800;
}

.viewBtn {
    background: var(--botique-blue);
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 10px;
}

.viewBtn:hover {
    background: #357bb8;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: var(--botique-blue);
    color: white;
    transition: 0.3s;
}

.pagination button:disabled {
    background: #ccc;
    cursor: default;
}

.orderItemsList {
    list-style-type: none;
    padding: 0;
}

.orderItem {
    background: #f8f9fb;
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;
}

.orderItem p {
    margin: 5px 0;
}