.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.chat {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 70vh;
    overflow-y: scroll;
    width: 100%;
    padding: 0px 20px;
}

.sendArea {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    width: 80%;
}

.sendArea input {
    backdrop-filter: blur(2px);
    background: #fafafa;
    background: var(--white, #fafafa);
    border: none;
    border-radius: 12px;
    box-shadow: 0 2px 8px 2px #37377426;
    font-family: Montserrat;
    padding: 12px 20px;
    width: 100%;
}

.sendArea span {
    cursor: pointer;
}

@media (max-width: 720px) {
    .container {
        width: 80%;
        padding: 0px 30px;
        margin: 0px;
        margin-bottom: 50px;
    }
}