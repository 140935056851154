.container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.card {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 10px;
}

.sectionTitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

.userDetail {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 12px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
}

.storeCard {
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.storeImage {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin-right: 15px;
}

.storeInfo h3 {
    margin: 0;
    font-size: 18px;
}

.storeLink {
    color: #007bff;
    font-weight: bold;
}

.productCard {
    width: calc(33% - 30px);
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.productImage {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
}

.productDesc {
    font-size: 14px;
    color: gray;
}

.expanded {
    max-height: none;
}

.toggleDescButton {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    text-align: left;
}

.toggleButton {
    display: block;
    margin: 10px auto;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.toggleButton:hover {
    background: #0056b3;
}

.noData {
    color: gray;
    text-align: center;
    margin-top: 10px;
}

/* Галерея */
.galleryOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery {
    background: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.galleryImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

/* Кнопки блокировки */
.blockButton {
    background: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.unblockButton {
    background: green;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.messageList {
    list-style: none;
    padding: 0;
}

.message {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.timestamp {
    font-size: 12px;
    color: gray;
    margin-top: 5px;
}