.header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.container {
    display: flex;
    background-color: var(--botique-blue);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 22px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s;
}

.logo:hover {
    opacity: 0.8;
}

/* Бургер-меню */
.burger {
    display: none;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 1100;
    /* Выше меню */
}

.menu {
    display: flex;
    gap: 12px;
}

.menu button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background 0.3s, color 0.3s;
}

.menu button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.logout {
    background: red;
    color: white;
    font-weight: bold;
    border-radius: 8px;
}

.logout:hover {
    background: darkred;
}

/* --- Адаптивность --- */
@media (max-width: 768px) {
    .burger {
        display: block;
        z-index: 1100;
        /* Выше меню */
    }

    .menu {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        background: var(--botique-blue);
        flex-direction: column;
        align-items: center;
        gap: 0;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }

    .menuOpen {
        transform: translateY(0);
    }

    .menu button {
        width: 100%;
        padding: 15px;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
}