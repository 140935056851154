.container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-x: auto;
    max-width: 1200px;
}

.headerTitle {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.headerTitle button {
    display: block;
    margin: 1rem 0;
    padding: 0.8rem 1.5rem;
    background-color: #419fd9;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
}

.title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}

.searchInput {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}

/* Обертка для прокрутки */
.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

/* Таблица */
.table {
    width: 100%;
    min-width: 700px;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.center {
    text-align: center;
}

/* Hover-эффект */
.clickableRow {
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.clickableRow:hover {
    background: #f5f5f5;
}

.red {
    color: red;
    font-weight: bold;
}

.green {
    color: green;
    font-weight: bold;
}

.noData {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: gray;
}

/* Пагинация */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pageButton {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
}

.pageButton:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.pageInfo {
    font-size: 16px;
}

/* Адаптивность */
@media (max-width: 768px) {
    .tableWrapper {
        overflow-x: auto;
        /* Включаем горизонтальную прокрутку */
    }

    .table {
        min-width: 700px;
        /* Минимальная ширина таблицы */
    }

    .searchInput {
        font-size: 14px;
    }

    .pageButton {
        padding: 8px 12px;
    }

    .pageInfo {
        font-size: 14px;
    }
}