.HomePage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    margin: 24px;
    max-width: 1200px;
    width: 100%;
}

.statisticsArea {
    display: flex;
    gap: 10px;
    /* flex-wrap: wrap; */
}

.scoresSection {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.supportSection {
    width: 70%;
}

.lastUpdates {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}