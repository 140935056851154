.card {
    padding: 20px;
    width: 100%;
    font-family: "Arial", sans-serif;
}

.title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
}

.infoBlock {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    background: #f9f9f9;
}

h3 {
    margin-bottom: 10px;
    color: #555;
}

p {
    margin: 5px 0;
    display: flex;
    align-items: center;
}

strong {
    color: #222;
    margin-right: 5px;
}

.icon {
    margin-right: 8px;
    color: #555;
}

.active {
    color: green;
    font-weight: bold;
}

.inactive {
    color: red;
    font-weight: bold;
}

.blocked {
    color: red;
    font-weight: bold;
}

.unblocked {
    color: green;
    font-weight: bold;
}

.enabled {
    color: green;
    font-weight: bold;
}

.disabled {
    color: red;
    font-weight: bold;
}

/* Кнопки блокировки */
.blockButton {
    width: 100%;
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.blockButton:hover {
    background-color: darkred;
}

.unblockButton {
    width: 100%;
    padding: 10px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.unblockButton:hover {
    background-color: darkgreen;
}