.pageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
}

.formContainer {
    flex: 1;
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.recipientsContainer {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.8rem;
}

.inputGroup label {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    color: #444;
}

.inputGroup input,
.inputGroup textarea {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border 0.2s ease-in-out;
}

.inputGroup input:focus,
.inputGroup textarea:focus {
    border-color: #419fd9;
    outline: none;
    box-shadow: 0 0 5px rgba(65, 159, 217, 0.4);
}

.selectButton {
    background-color: #419fd9;
    color: white;
    border: none;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s ease-in-out, transform 0.1s ease-in-out;
    text-align: center;
}

.selectButton:hover {
    background-color: #317bb8;
}

.selectButton:active {
    transform: scale(0.97);
}


.sendButton {
    background-color: #419fd9;
    color: #fff;
    border: none;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.sendButton:hover {
    background-color: #317bb8;
}

.previewContainer {
    flex: 1;
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 600px;
}

@media (max-width: 1024px) {
    .pageContainer {
        flex-direction: column;
        align-items: center;
    }

    .formContainer,
    .previewContainer {
        max-width: 100%;
    }
}


/* EMAIL PREVIEW */
.emailContainer {
    max-width: 600px;
    margin: 40px auto;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.bannerHeader,
.bannerFooter {
    text-align: center;
    padding: 20px;
}

.content {
    padding: 20px;
    color: #333333;
    line-height: 1.5;
}

.bannerHeader img,
.bannerImage img,
.bannerFooter img {
    width: 100%;
    height: auto;
    display: block;
}

.callToAction {
    text-align: center;
    margin: 20px 0;
}

.callToAction a {
    text-decoration: none;
    padding: 15px 30px;
    background: linear-gradient(90deg, #2ecc71, #27ae60);
    color: #ffffff;
    border-radius: 8px;
    font-weight: bold;
    display: inline-block;
    transition: background 0.3s, transform 0.3s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.callToAction a:hover {
    background: linear-gradient(90deg, #27ae60, #2ecc71);
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.signature {
    font-size: 12px;
    color: #777777;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #ddd;
}

@media only screen and (max-width: 600px) {
    .emailContainer {
        margin: 20px;
    }

    .content,
    .bannerHeader,
    .bannerFooter,
    .signature {
        padding: 15px;
    }
}