.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(65, 159, 217, 0.3);
    border-top-color: #419fd9;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loadingText {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}