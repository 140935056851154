:root {
    --primary-color: #419fd9;
    --secondary-color: #f4f7fc;
    --text-color: #333;
    --card-bg: white;
    --hover-bg: #eef3f9;
    --transition: 0.3s ease-in-out;
}

.crmContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

h1 {
    font-size: 28px;
    color: var(--text-color);
    margin-bottom: 20px;
}

.columnsContainer {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding: 10px;
}

.column {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    background: linear-gradient(145deg, #e3eaf3, var(--secondary-color));
    padding: 16px;
    border-radius: 12px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
}

.column h2 {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.countBadge {
    background: var(--primary-color);
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 12px;
}

.card {
    background: var(--card-bg);
    padding: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    cursor: grab;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: var(--transition);
}

.card:hover {
    background: var(--hover-bg);
    transform: scale(1.03);
}

.card p {
    margin: 0;
    color: var(--text-color);
}

.email {
    font-weight: bold;
    font-size: 14px;
}

.updated {
    font-size: 12px;
    color: gray;
}

.card button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 6px 12px;
    margin-top: 6px;
    cursor: pointer;
    border-radius: 6px;
    transition: var(--transition);
    font-size: 14px;
}

.card button:hover {
    background: #357ebd;
}

@media (max-width: 768px) {
    .columnsContainer {
        flex-wrap: wrap;
        justify-content: center;
    }

    .column {
        min-width: 100%;
    }
}