.container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

h1 {
    text-align: center;
    margin-bottom: 1rem;
}

.tableTitle {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.createButton {
    display: block;
    margin: 1rem 0;
    padding: 0.8rem 1.5rem;
    background-color: #419fd9;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
}

.createButton:hover {
    background-color: #317bb8;
}

.historyTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.historyTable th,
.historyTable td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.success {
    color: green;
    font-weight: bold;
}

.error {
    color: red;
    font-weight: bold;
}

.viewButton {
    background: #4caf50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.viewButton:hover {
    background: #45a049;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
}

.modal h2 {
    margin-bottom: 1rem;
}

.banner {
    max-width: 100%;
    margin-top: 1rem;
    border-radius: 5px;
}

.closeButton {
    display: block;
    margin: 1rem auto 0;
    padding: 0.6rem 1rem;
    background: #d32f2f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeButton:hover {
    background: #b71c1c;
}

.noData {
    text-align: center;
    font-size: 1rem;
    color: #666;
    margin-top: 2rem;
}