.statusContainer {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    max-width: 700px;
    width: 100%;
}

.statusContainer select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
}